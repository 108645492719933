import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Input, Modal, Select, Empty} from 'antd';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import Icon from '@mui/material/Icon';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import MDTypography from '../../../views/components/MDTypography';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import DataTable from '../../components/DataTable';
import LabelFilterView from '../../components/LabelFilterView';
import MerchantModal from '../../components/MerchantModal';

import Styles from './styles';
import Strings from '../../../constants/strings';
import {
  fetchMerchantsApiCall,
  updateMerchantApiCall,
  fetchClientsByNameApiCall,
  fetchMerchantCrmsApiCall,
  updateMerchantCrmApiCall,
  createMerchantApiCall,
} from '../../../services/api/apiCalls';
import {formatPrice, getQueryParams, centToUSD} from '../../../utility/helpers';
import {merchantValidation} from '../../../utility/validations';
import {showToast} from '../../../redux/slices/commonSlice';

const MerchantPage = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const clientIdParam = searchParams.get('clientId');
  const clientNameParam = searchParams.get('clientName');

  const [storeMerchants, setStoreMerchants] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [merchantInfoData, setMerchantInfoData] = useState('');
  const [storeClients, setStoreClients] = useState([]);
  const [storeCrms, setStoreCrms] = useState([]);
  const [storeSelectedCrms, setStoreSelectedCrms] = useState([]);

  const [descriptor, setDescriptor] = useState('');
  const [clientId, setClientId] = useState(clientIdParam);
  const [selectedClient, setSelectedClient] = useState(clientNameParam);

  const [isLoading, setIsLoading] = useState(false);
  const [isMerchantBtnLoading, setIsMerchantBtnLoading] = useState(false);
  const [isClientListLoading, setIsClientListLoading] = useState(false);
  const [isCrmListLoading, setIsCrmListLoading] = useState(false);
  const [isCrmBtnLoading, setIsCrmBtnLoading] = useState(false);

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isCrmModalVisible, setIsCrmModalVisible] = useState(false);
  const [isMerchantUpdate, setIsMerchantUpdate] = useState(false);

  useEffect(() => {
    getMerchantsListData(currentPage);
    getClientsByNameListData(clientNameParam);
  }, []);

  const debouncedFetchOptions = useCallback(
    debounce(searchValue => {
      getClientsByNameListData(searchValue);
    }, 500),
    [],
  );

  const formik = useFormik({
    initialValues: {
      midNum: merchantInfoData?.midNum,
      dba: merchantInfoData?.dba,
      descriptor: merchantInfoData?.descriptor,
      bank: merchantInfoData?.bank,
      phoneNum: merchantInfoData?.phoneNum,
      minTransactionAmount: merchantInfoData?.minTransactionAmount
        ? centToUSD(merchantInfoData?.minTransactionAmount)?.toString()
        : '',
      maxTransactionAmount: merchantInfoData?.maxTransactionAmount
        ? centToUSD(merchantInfoData?.maxTransactionAmount)?.toString()
        : '',
      ...(!isMerchantUpdate && {
        clientId: null,
      }),
    },
    enableReinitialize: true,
    validationSchema: () =>
      merchantValidation(formik.values, !isMerchantUpdate),
    onSubmit: values => handleSubmitClicked(values),
  });

  // api call code
  const getMerchantsListData = async page => {
    setIsLoading(true);
    setCurrentPage(page);
    const params = {
      descriptor,
      clientId,
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchMerchantsApiCall(queryString);
    if (responseData?.success) {
      setStoreMerchants(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleResetClicked = async page => {
    setDescriptor('');
    setClientId(null);
    setSelectedClient(null);

    setIsLoading(true);
    setCurrentPage(page);

    const params = {
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchMerchantsApiCall(queryString);
    if (responseData?.success) {
      setStoreMerchants(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
    getClientsByNameListData();
  };

  const handleSubmitClicked = async values => {
    setIsMerchantBtnLoading(true);

    const responseData = isMerchantUpdate
      ? await updateMerchantApiCall(merchantInfoData?.id, values)
      : await createMerchantApiCall({
          ...values,
          clientId: values.clientId,
        });

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      getMerchantsListData(isMerchantUpdate ? currentPage : 1);
      onUpdateModalCancel();
    }

    setIsMerchantBtnLoading(false);
  };

  const getClientsByNameListData = async (name = '') => {
    setStoreClients([]);
    setIsClientListLoading(true);

    const responseData = await fetchClientsByNameApiCall(name);
    if (responseData?.success) {
      const data = responseData?.data.map(item => {
        return {
          value: item?.id,
          label: item?.merchantName,
        };
      });
      setStoreClients(data);
    }

    setIsClientListLoading(false);
  };

  const getMerchantCrmsListData = async merchantId => {
    setIsCrmListLoading(true);
    setIsLoading(true);

    const responseData = await fetchMerchantCrmsApiCall(merchantId);
    if (responseData?.success) {
      const crmData = responseData?.data.map(item => {
        return {
          value: item?.id,
          label: item?.discriminatorLabel,
          data: item,
        };
      });
      const selectedValues = responseData?.data
        .filter(item => item?.crmMerchantId)
        .map(item => {
          return {
            value: item?.id,
            label: item?.discriminatorLabel,
            data: item,
          };
        });

      setStoreCrms(crmData);
      setStoreSelectedCrms(selectedValues);
    }

    setIsCrmListLoading(false);
    setIsLoading(false);
  };

  const handleAddCrmClicked = async () => {
    setIsCrmBtnLoading(true);
    const bodyData = {
      crms: storeSelectedCrms.map(item => {
        return {
          crmId: item?.value,
        };
      }),
    };

    const responseData = await updateMerchantCrmApiCall(
      merchantInfoData?.id,
      bodyData,
    );
    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      onAddCrmModalCancel();
    }

    setIsCrmBtnLoading(false);
  };

  // logical code
  // const checkIsSearchDisabled = () => {
  //   if (descriptor || clientId) return false;
  //   else return true;
  // };

  const onDetailsClicked = async item => {
    setMerchantInfoData(item);
    setIsInfoModalVisible(true);
  };

  const onAddClicked = () => {
    formik.resetForm();
    setIsUpdateModalVisible(true);
    setMerchantInfoData('');
    setIsMerchantUpdate(false);
  };

  const onEditClicked = item => {
    formik.resetForm();
    setIsUpdateModalVisible(true);
    setMerchantInfoData(item);
    setIsMerchantUpdate(true);
  };

  const onAddCrmClicked = item => {
    setStoreCrms([]);
    setStoreSelectedCrms([]);
    setIsCrmModalVisible(true);
    setMerchantInfoData(item);
    getMerchantCrmsListData(item?.id);
  };

  const onModalCancel = () => {
    setMerchantInfoData('');
    setIsInfoModalVisible(false);
  };

  const onUpdateModalCancel = () => {
    setIsUpdateModalVisible(false);
    formik.resetForm();
  };

  const onAddCrmModalCancel = () => {
    setStoreCrms([]);
    setStoreSelectedCrms([]);
    setIsCrmModalVisible(false);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getMerchantsListData(1);
    }
  };

  // render ui
  const renderHeader = () => {
    return (
      <MDBox>
        <MDButton onClick={onAddClicked}>
          <Icon sx={Styles.addIcon}>add</Icon>
          {Strings.addMerchant}
        </MDButton>
      </MDBox>
    );
  };

  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        <MDBox sx={Styles.searchView}>
          {/* descriptor */}
          <LabelFilterView label={Strings.descriptor}>
            <Input
              ref={inputRef}
              value={descriptor}
              placeholder={Strings.enterDescriptor}
              size={'large'}
              onChange={text => setDescriptor(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
          <LabelFilterView label={Strings.clients}>
            <Select
              showSearch
              filterOption={false}
              value={selectedClient}
              options={storeClients}
              size={'large'}
              optionFilterProp="label"
              placeholder={Strings.selectClient}
              onChange={(value, data) => {
                setClientId(value);
                setSelectedClient(data?.label);
              }}
              onSearch={debouncedFetchOptions}
              notFoundContent={
                isClientListLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              style={Styles.inputView}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            // disabled={checkIsSearchDisabled()}
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked(1)}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getMerchantsListData(1)}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  const renderTableSection = () => {
    return (
      <DataTable
        currentPage={currentPage}
        totalPage={paginationMeta?.count}
        paginationOnChange={page => getMerchantsListData(page)}
        renderHeader={renderHeader()}
        table={{
          columns: [
            {Header: Strings.mid, accessor: 'midNum'},
            {Header: Strings.client, accessor: 'client'},
            {Header: Strings.dba, accessor: 'dba'},
            {Header: Strings.descriptor, accessor: 'descriptor'},
            {Header: Strings.bankName, accessor: 'bankName'},
            {Header: Strings.maxAmount, accessor: 'maxAmount'},
            {Header: Strings.actions, accessor: 'actions', width: '10%'},
          ],
          rows: storeMerchants?.map(item => {
            return {
              midNum: (
                <MDButton
                  variant="text"
                  color="info"
                  onClick={() => onDetailsClicked(item)}
                  sx={Styles.midBtn}>
                  {item?.midNum || '--'}
                </MDButton>
              ),
              client: item?.merchantName || '--',
              dba: item?.dba || '--',
              descriptor: item?.descriptor || '--',
              bankName: item?.bank || '--',
              maxAmount: formatPrice(item?.maxTransactionAmount),
              actions: (
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={() => onEditClicked(item)}
                    sx={Styles.editBtn}>
                    {Strings.edit}
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={() => onAddCrmClicked(item)}>
                    {Strings.attachCRM}
                  </MDButton>
                </MDBox>
              ),
            };
          }),
        }}
      />
    );
  };

  const renderInfoLabel = (label, value) => {
    return (
      <MDBox sx={Styles.infoLabelView}>
        <MDTypography variant="body2" fontWeight="bold" mr={1}>
          {label}:
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {value || '--'}
        </MDTypography>
      </MDBox>
    );
  };

  const renderDetailsModal = () => {
    return (
      <Modal
        title={Strings.merchantDetails}
        open={isInfoModalVisible}
        footer={null}
        onCancel={onModalCancel}
        width={'45%'}
        style={Styles.modalStyle}>
        <MDBox sx={Styles.modalBodyContainer}>
          <MDBox sx={Styles.infoLeftView}>
            {renderInfoLabel(Strings.mid, merchantInfoData?.midNum)}
            {renderInfoLabel(Strings.dba, merchantInfoData?.dba)}
            {renderInfoLabel(Strings.descriptor, merchantInfoData?.descriptor)}
            {renderInfoLabel(Strings.client, merchantInfoData?.merchantName)}
          </MDBox>
          <MDBox>
            {renderInfoLabel(Strings.bankName, merchantInfoData?.bank)}
            {renderInfoLabel(Strings.phoneNumber, merchantInfoData?.phoneNum)}
            {renderInfoLabel(
              Strings.minAmount,
              formatPrice(merchantInfoData?.minTransactionAmount),
            )}
            {renderInfoLabel(
              Strings.maxAmount,
              formatPrice(merchantInfoData?.maxTransactionAmount),
            )}
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  const renderAddCrmModal = () => {
    return (
      <Modal
        title={Strings.attachCRM}
        open={isCrmModalVisible}
        footer={null}
        onCancel={onAddCrmModalCancel}
        width={'35%'}
        style={Styles.modalStyle}>
        {storeCrms.length === 0 ? (
          <MDBox sx={Styles.addCrmWaringView}>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              {Strings.noCRMsAdded}
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox sx={Styles.addCrmModalBodyContainer}>
            <Select
              mode="multiple"
              size="large"
              optionFilterProp="label"
              value={storeSelectedCrms}
              options={storeCrms}
              style={Styles.addCrmDropdown}
              placeholder={Strings.pleaseSelect}
              onChange={(value, option) => {
                setStoreSelectedCrms(option);
              }}
              notFoundContent={
                isCrmListLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
            />
            <MDButton
              loading={isCrmBtnLoading}
              sx={Styles.submitBtn}
              onClick={handleAddCrmClicked}>
              {Strings.save}
            </MDButton>
          </MDBox>
        )}
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {/* table section */}
      {renderTableSection()}
      {/* modal section */}
      {renderDetailsModal()}
      {!isCrmListLoading && renderAddCrmModal()}
      <MerchantModal
        isMerchantUpdate={isMerchantUpdate}
        formik={formik}
        isModalVisible={isUpdateModalVisible}
        isBtnLoading={isMerchantBtnLoading}
        handleModalCancel={onUpdateModalCancel}
      />
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default MerchantPage;
