const Strings = {
  validationMsg: {
    somethingWentWrong: 'Something went wrong, please try again',
    enterValidEmail: 'Please enter valid email address.',
    enterValidPhone: 'Please enter valid phone number.',
    enterValidPass: 'Please enter valid password.',
    enterValidFirstName: 'Please enter valid first name.',
    enterValidLastName: 'Please enter valid last name.',
    youHaveEnterLeastDigit: 'You have to enter at least 8 characters.',
    newPassConfirmPassNotMatch:
      'New password and Confirm password do not match.',
    enterMerchantName: 'Please enter valid merchant name.',
    enterMidNumber: 'Please enter mid number.',
    enterDba: 'Please enter dba.',
    enterDescriptor: 'Please enter descriptor.',
    enterBankName: 'Please enter bank name.',
    enterPhoneNumber: 'Please enter phone number.',
    enterMinAmount: 'Please enter min amount.',
    enterMaxAmount: 'Please enter max amount.',
    MaxAmountGreaterThanMin:
      'Max amount must be greater than or equal to min amount.',
    amountMustNumber: 'Amount must be number.',
    enterServiceProvider: 'Please select service provider.',
    enterFirstName: 'Please enter first name.',
    enterLastName: 'Please enter last name.',
    enterEmail: 'Please enter email address.',
    enterClient: 'Please select a client.',
  },
  disputeAlert: 'DisputeAlert',
  dashboard: 'Dashboard',
  merchants: 'Merchants',
  merchant: 'Merchant',
  clients: 'Clients',
  profile: 'Profile',
  accounts: 'Accounts',
  signIn: 'Sign In',
  forgotPassword: 'Forgot Password',
  alerts: 'Alerts',
  resetPassword: 'Reset Password',
  youWillReceiveEmail:
    "Enter the email address you use on DisputeAlert. We'll send you a link to reset your password.",
  email: 'Email',
  password: 'Password',
  reset: 'Reset',
  backToSignIn: 'Back to Sign In',
  error: 'Error',
  success: 'Success',
  erorr404: '404',
  opsPageDoesntExist: `Oops! The page you're looking for doesn't exist.`,
  goBackToDashboard: 'Go Back To Dashboard',
  requestResetPassword: 'Request reset password',
  pleaseCreateNewPass: 'Please create a new password.',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  businessName: 'Business Name',
  businessEmail: 'Business Email',
  enterBusinessName: 'Enter business name',
  search: 'Search',
  edit: 'Edit',
  addClient: 'Add Client',
  editClient: 'Edit Client',
  addMerchant: 'Add Merchant',
  editMerchant: 'Edit Merchant',
  enterEmail: 'Enter email',
  enterPass: 'Enter password',
  enterNewPass: 'Enter new password',
  enterConfirmPass: 'Enter confirm password',
  submit: 'Submit',
  descriptor: 'Descriptor',
  enterDescriptor: 'Enter descriptor',
  dataSource: 'Data Source',
  selectDataSource: 'Select data source',
  caseDate: 'Case Date',
  status: 'Status',
  selectStatus: 'Select status',
  caseId: 'Case Id',
  mid: 'MID',
  txnDate: 'Txn Date',
  amount: 'Amount',
  ethoca: 'Ethoca',
  verifi: 'Verifi',
  init: 'Init',
  processing: 'Processing',
  refunded: 'Refunded',
  notFound: 'Not found',
  outTransactionRange: 'Out of transaction range',
  retry: 'Retry',
  failed: 'Failed',
  today: 'Today',
  last7Days: 'Last 7 Days',
  last14Days: 'Last 14 Days',
  last30Days: 'Last 30 Days',
  last90Days: 'Last 90 Days',
  alertDetails: 'Alert Details',
  arn: 'ARN',
  bin: 'BIN',
  dba: 'DBA',
  cardLast4: 'Card Last 4',
  bankName: 'Bank Name',
  maxAmount: 'Max Amount',
  minAmount: 'Min Amount',
  merchantDetails: 'Merchant Details',
  phoneNumber: 'Phone Number',
  noDataFound: 'No Data Found',
  actions: 'Actions',
  enter: 'Enter',
  selectClient: 'Select client',
  clientName: 'Client Name',
  client: 'Client',
  update: 'Update',
  save: 'Save',
  addCrm: 'Add CRM',
  editCrm: 'Edit CRM',
  provider: 'Provider',
  crms: 'CRMs',
  crm: 'CRM',
  serviceProvider: 'Service Provider',
  SelectServiceProvider: 'Select service provider',
  pleaseEnter: 'Please enter',
  pleaseSelect: 'Please select',
  sticky: 'Sticky',
  konnektive: 'Konnektive',
  viewCRMs: 'View CRMs',
  viewMerchants: 'View Merchants',
  firstName: 'First Name',
  enterFirstName: 'Enter first name',
  lastName: 'Last Name',
  enterLastName: 'Enter last name',
  role: 'Role',
  logout: 'Logout',
  admin: 'Admin',
  superAdmin: 'Super Admin',
  addAccount: 'Add Account',
  editAccount: 'Edit Account',
  allAlerts: 'All Alerts',
  last24HrAlerts: 'Last 24 Hrs. Alerts',
  testConnection: 'Test Connection',
  alertId: 'Alert Id',
  notSet: 'Not Set',
  alertCreated: 'Alert Created',
  createdDate: 'Created Date',
  attachCRM: 'Attach CRM',
  noCRMsAdded:
    'There are no CRMs defined for the selected client. Please add one before you can proceed.',
  apiUserName: 'API User Name',
};

export default Strings;
