import dayjs from 'dayjs';

import Strings from './strings';

const Jsons = {
  dataSource: [
    {
      value: 'ethoca',
      label: Strings.ethoca,
    },
    {
      value: 'verifi',
      label: Strings.verifi,
    },
  ],
  alertStatus: [
    {
      value: 'init',
      label: Strings.init,
      color: 'LightBlue',
    },
    {
      value: 'processing',
      label: Strings.processing,
      color: 'DodgerBlue',
    },
    {
      value: 'refunded',
      label: Strings.refunded,
      color: 'LimeGreen',
    },
    {
      value: 'not-found',
      label: Strings.notFound,
      color: 'DarkGray',
    },
    {
      value: 'out-of-transaction-range',
      label: Strings.outTransactionRange,
      color: 'MediumPurple',
    },
    {
      value: 'retry',
      label: Strings.retry,
      color: 'Tomato',
      isRetryAttempt: true,
    },
    {
      value: 'failed',
      label: Strings.failed,
      color: 'Red',
    },
  ],
  dateRangePresets: [
    {
      label: Strings.today,
      value: [dayjs(), dayjs()],
    },
    {
      label: Strings.last7Days,
      value: [dayjs().add(-6, 'd'), dayjs()],
    },
    {
      label: Strings.last14Days,
      value: [dayjs().add(-13, 'd'), dayjs()],
    },
    {
      label: Strings.last30Days,
      value: [dayjs().add(-29, 'd'), dayjs()],
    },
    {
      label: Strings.last90Days,
      value: [dayjs().add(-89, 'd'), dayjs()],
    },
  ],
  serviceProviders: [
    {
      value: 'sticky',
      label: Strings.sticky,
    },
    {
      value: 'konnektive',
      label: Strings.konnektive,
    },
  ],
};

export default Jsons;
