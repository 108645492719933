import moment from 'moment';

import Enums from '../constants/enums';

export const getQueryParams = (params = '') => {
  const queryParams = new URLSearchParams();

  if (!params) return '';

  for (const key in params) {
    if (params[key]) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
};

export const formatPrice = (amount = '', currency = '') => {
  if (!amount) return '--';

  switch (currency) {
    case Enums.CURRENCY.USD:
      return `$${centToUSD(amount)}`;
    default:
      return `$${centToUSD(amount)}`;
  }
};

export const formatDate = (date = '') => {
  if (!date) return '--';

  return moment(date).format(Enums.DATE_FORMAT.MM_DD_YYYY);
};

export const formatDateAndTime = (date = '') => {
  if (!date) return '--';

  return moment(date).format(Enums.DATE_FORMAT.MM_DD_YYYY_HH_MM);
};

export const centToUSD = (amount = 0) => {
  if (!amount) return 0;

  return amount / 100;
};
