import * as Yup from 'yup';

import Strings from '../constants/strings';
import Regex from '../constants/regex';

export const checkNullOrEmptyValue = value => {
  if (typeof value !== 'undefined' && value !== null) return value;
  else return '';
};

export const merchantValidation = (values, isMerchantAdd) => {
  return Yup.object().shape({
    midNum: Yup.string().required(Strings.validationMsg.enterMidNumber),
    dba: Yup.string().required(Strings.validationMsg.enterDba),
    descriptor: Yup.string().required(Strings.validationMsg.enterDescriptor),
    bank: Yup.string().required(Strings.validationMsg.enterBankName),
    phoneNum: Yup.string()
      .required(Strings.validationMsg.enterPhoneNumber)
      .matches(Regex.phoneNoValidation, Strings.validationMsg.enterValidPhone),
    minTransactionAmount: Yup.number().typeError(
      Strings.validationMsg.amountMustNumber,
    ),
    maxTransactionAmount: Yup.number()
      .typeError(Strings.validationMsg.amountMustNumber)
      .test(
        'is-greater-than-min',
        Strings.validationMsg.MaxAmountGreaterThanMin,
        function (value) {
          const {minTransactionAmount} = this.parent;
          if (values?.minTransactionAmount && values?.maxTransactionAmount) {
            return value >= minTransactionAmount;
          }
          return true;
        },
      ),
    ...(isMerchantAdd && {
      clientId: Yup.string().required(Strings.validationMsg.enterClient),
    }),
  });
};

export const clientCrmValidation = isCrmAdd => {
  return Yup.object().shape({
    serviceProvider: Yup.string().required(
      Strings.validationMsg.enterServiceProvider,
    ),
    email: Yup.string()
      .required(Strings.validationMsg.enterValidEmail)
      .matches(Regex.emailValidation, Strings.validationMsg.enterValidEmail),
    ...(isCrmAdd && {
      clientId: Yup.string().required(Strings.validationMsg.enterClient),
    }),
  });
};

export const crmDynamicFieldValidation = (config = []) => {
  const schema = {};
  config.forEach(field => {
    let validation = Yup.string();

    if (field.type === 'number') {
      validation = Yup.number();
    }

    if (field.validation.required) {
      validation = validation.required(`${Strings.pleaseEnter} ${field.name}.`);
    }

    schema[field.name] = validation;
  });
  return Yup.object().shape(schema);
};
